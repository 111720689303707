import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "@components/layout";
import GetInTouchForm from "@modules/get-in-touch-form";

const Contact = () => {
  const {
    markdownRemark: {
      frontmatter: { pageName },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/contact/" }) {
          frontmatter {
            pageName
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <GetInTouchForm />
    </Layout>
  );
};

export default Contact;
