import React, { useState, useRef } from "react";
import useOnClickOutside from "@helpers/use-on-click-outside";
import * as Colors from "@constants/colors";
import {
  Label,
  SelectWrapper,
  SelectInput,
  Error,
  OptionsWrapper,
  Option,
} from "./select.styled";

const Select = ({
  borderColor = Colors.white,
  color = Colors.white,
  error,
  id,
  label,
  name,
  placeholder = "",
  placeholderColor = Colors.gray,
  options,
}) => {
  const [fieldValue, setFieldValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const handleIsOpenChange = newValue => () => setIsOpen(newValue);

  const handleOptionClick = value => () => {
    setIsOpen(false);
    setFieldValue(value);
  };

  return (
    <div ref={ref}>
      <Label htmlFor={name} color={color}>
        {label}
      </Label>
      <SelectWrapper>
        <SelectInput
          id={id}
          name={name}
          color={color}
          value={fieldValue}
          placeholder={placeholder}
          borderColor={borderColor}
          onClick={handleIsOpenChange(!isOpen)}
          placeholderColor={placeholderColor}
          onChange={() => {}}
        />
        <OptionsWrapper isOpen={isOpen}>
          {options.map(({ text, value }) => (
            <Option
              key={value}
              isSelected={value === fieldValue}
              onClick={handleOptionClick(value)}
              value={value}
            >
              {text}
            </Option>
          ))}
        </OptionsWrapper>
      </SelectWrapper>
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default Select;
