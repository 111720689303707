import React, { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import GetInTouchForm from "./form";
import { applyParallaxPhoto } from "@helpers/animations";
import Image from "@components/image";
import { PhotoContainer } from "./get-in-touch-form.styled";

const GetInTouchFormWrapper = () => {
  const {
    markdownRemark: {
      frontmatter: {
        introBackgroundColor,
        largeHeader,
        description,
        formBackgroundColor,
        buttonLabel,
        photo,
        alt,
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/contact/" }) {
          frontmatter {
            introBackgroundColor
            largeHeader
            description
            formBackgroundColor
            buttonLabel
            photo {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    `
  );

  const photoContainerRef = useRef(null);
  const photoRef = useRef(null);

  const textOptions = {
    introBackgroundColor,
    largeHeader,
    description,
    formBackgroundColor,
    buttonLabel,
    alt,
  };

  useEffect(() => {
    // apply parallax image
    const wrapperEl = photoContainerRef.current;
    const imageEl = photoRef.current;
    const { timeline, scrollTriggerInstance } = applyParallaxPhoto(
      wrapperEl,
      imageEl
    );

    // destroy animations on unmount
    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <>
      <GetInTouchForm {...textOptions} />
      <PhotoContainer ref={photoContainerRef}>
        <div ref={photoRef}>
          <Image image={photo} width="100%" />
        </div>
      </PhotoContainer>
    </>
  );
};

export default GetInTouchFormWrapper;
