import React from "react";
import { Label, StyledTextInput, ErrorMessage } from "./text-input.styled";
import * as Colors from "@constants/colors";

const TextInput = ({
  borderColor = Colors.white,
  color = Colors.white,
  id,
  label,
  name,
  placeholder = "",
  placeholderColor = Colors.gray,
  type = "text",
  required = false,
  error,
}) => (
  <>
    <Label htmlFor={id} color={color}>
      {label}
    </Label>
    <StyledTextInput
      borderColor={borderColor}
      color={color}
      id={id}
      name={name}
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      type={type}
      required={required}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </>
);

export default TextInput;
