import styled from "styled-components";
import * as Colors from "@constants/colors";

const Label = styled.label`
  color: ${({ color }) => color};
  font-weight: 600;
  display: block;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 6px;
`;

const getPlaceholderStyles = placeholderColor => `
  color: ${placeholderColor};
  font-size: 18px;
  line-height: 25px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const SelectInput = styled.input`
  position: relative;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  background: transparent;
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 25px;
  padding-bottom: 12px;
  width: 100%;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
`;

const Error = styled.p`
  color: ${Colors.sunsetCoral};
  font-weight: bold;
  margin: 4px 0;
  font-size: 18px;
  line-height: 25px;
`;

const OptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
  background: ${Colors.white};
  z-index: 10;
  border-radius: 5px;
  padding: 24px 24px;
  ${({ isOpen }) => (isOpen ? "display: block;" : "display: none;")}
`;

const Option = styled.p`
  cursor: pointer;
  color: ${({ isSelected }) =>
    isSelected ? Colors.charcoal : Colors.brownLighter2};
  margin-bottom: 25px;
  font-weight: bold;

  :hover {
    color: ${Colors.charcoal};
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

export { Label, SelectWrapper, SelectInput, Error, OptionsWrapper, Option };
