import styled from "styled-components";
import * as Colors from "@constants/colors";
import * as Fonts from "@constants/fonts";

const Label = styled.label`
  color: ${({ color }) => color};
  font-weight: 600;
  display: block;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 6px;
  text-transform: uppercase;
  span {
    color: ${Colors.grayLighter};
    text-transform: none;
  }
`;

const getPlaceholderStyles = placeholderColor => `
  color: ${placeholderColor};
  font-size: 18px;
  line-height: 26px;
`;

const StyledTextInput = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  background: transparent;
  color: ${({ color }) => color};
  font-size: 24px;
  line-height: 26px;
  width: 100%;
  padding-bottom: 12px;

  :focus {
    border-bottom-color: ${Colors.beachTeal};
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    ${({ placeholderColor }) => getPlaceholderStyles(placeholderColor)};
  }
`;

const ErrorMessage = styled.p`
  display: inline-block;
  margin-top: 8px;
  padding: 0px 8px;
  font-family: ${Fonts.ProximaNovaFont};
  font-size: 12px;
  line-height: 24px;
  color: ${Colors.white};
  background-color: ${Colors.sunsetCoral};
  border-radius: 2px;
`;

export { Label, StyledTextInput, ErrorMessage };
