import styled from "styled-components";
import Button from "@components/button";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";

const HeaderContainer = styled.div`
  background: ${({ containerBackground = Colors.seafoamLighter }) =>
    containerBackground};
  padding: 112px 0;
  text-align: center;

  @media (max-width: ${Screens.lg}) {
    padding: 64px 0;
  }
`;

const Header = styled.h1`
  font-family: ${Fonts.ClearfaceFont};
  font-size: 48px;
  line-height: 1.3;
  margin: 0 0 20px;

  @media (max-width: ${Screens.lg}) {
    font-size: 32px;
  }
`;

const Info = styled.p`
  font-size: 20px;
  line-height: 1.4;
  margin: 0;

  @media (max-width: ${Screens.lg}) {
    font-size: 18px;
  }
`;

const FormContainer = styled.div`
  background-color: ${({ backgroundColor = Colors.charcoal }) =>
    backgroundColor};
  padding: 112px 0;
  color: ${Colors.offWhite};

  @media (max-width: ${Screens.lg}) {
    padding: 64px 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
`;

const FormGroup = styled.div`
  width: 47.5%;
  max-width: 415px;
  height: 53px;
  margin: 0 0 80px;

  @media (max-width: ${Screens.lg}) {
    width: 100%;
    margin: 0 auto 60px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 24px;

  @media (max-width: ${Screens.lg}) {
    width: 100%;
    max-width: 415px;
    margin: 16px auto 0;
  }
`;

const PhotoContainer = styled.div`
  width: 100%;
  max-height: 540px;
  background: ${Colors.beachTealLighter};
  overflow: hidden;
`;

export {
  HeaderContainer,
  Header,
  Info,
  StyledForm,
  FormContainer,
  FormGroup,
  StyledButton,
  PhotoContainer,
};
