import React from "react";
import { Wrapper } from "@components/shared.styled";
import TextInput from "@components/text-input";
import Select from "@components/select";
import {
  HeaderContainer,
  Header,
  Info,
  StyledForm,
  FormContainer,
  FormGroup,
  StyledButton,
} from "./get-in-touch-form.styled";

const campaignBudgetOptions = [
  { text: "Not Applicable", value: "Not Applicable" },
  { text: "< $50,000", value: "< $50,000" },
  { text: "$50,000 - $250,000", value: "$50,000 - $250,000" },
  { text: "$250,000 +", value: "$250,000 +" },
];

const Form = ({
  introBackgroundColor,
  largeHeader,
  description,
  formBackgroundColor,
  buttonLabel,
}) => (
  <>
    <HeaderContainer containerBackground={introBackgroundColor}>
      <Wrapper>
        <Header>{largeHeader}</Header>
        <Info>{description}</Info>
      </Wrapper>
    </HeaderContainer>
    <FormContainer backgroundColor={formBackgroundColor}>
      <Wrapper>
        <StyledForm
          name="Contact Page Submission"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thanks-for-submission"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" />
            </label>
          </p>
          <FormGroup>
            <TextInput
              label="FULL NAME*"
              name="fullName"
              id="full-name"
              placeholder="Your full name"
              required
            />
          </FormGroup>
          <FormGroup>
            <TextInput
              label="WORK EMAIL*"
              name="email"
              id="email"
              placeholder="email@company.com"
              type="email"
              required
            />
          </FormGroup>
          <FormGroup>
            <TextInput
              label="COMPANY*"
              name="company"
              id="company"
              placeholder="Your company"
              required
            />
          </FormGroup>
          <FormGroup>
            <TextInput
              label="TITLE*"
              name="title"
              id="title"
              placeholder="Your job title"
              required
            />
          </FormGroup>
          <FormGroup>
            <TextInput
              placeholder="Your phone number"
              label="PHONE NUMBER"
              name="phoneNumber"
              id="phone-number"
            />
          </FormGroup>
          <FormGroup>
            <Select
              name="campaignBudget"
              label="CAMPAIGN BUDGET"
              options={campaignBudgetOptions}
              placeholder="Select your budget"
            />
          </FormGroup>
          <StyledButton type="submit" text={buttonLabel} />
        </StyledForm>
      </Wrapper>
    </FormContainer>
  </>
);

export default Form;
